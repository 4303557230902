import { createElement as _C, useState, createContext, useContext } from 'react';
import '../../resources/mooncake.less';
import { Row, post, APP_ID, timeout } from '../common';
import { Select, ISelectProps } from '../select';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';



export interface IGoodsType {
  id: number;
  isShow: boolean;
  name: string;
  description: string;
  price: number;
  vipPrice: number;
  originPrice: number;
  imgs: string[];
  isValid: boolean;
}


interface MoonCakeDetail {
  unitPrice: number;
  quantity: number;
  shopName: string;
  goodId: number;
}

interface MoonCakeAddress {
  address: string;
  consignee: string;
  tel: string;
}

export interface FormData {
  // deliveryTime: string;
  openId: string;
  referencePhone: string;
  moonCakeDetails: MoonCakeDetail[];
  totalMoney: number;
  remark: string;
  userName: string;
  moonCakeAddress: MoonCakeAddress | null;
  phone: string;
  isSelf: number;
}

export interface OrderData extends FormData {
  orderId: number;
  orderNo: string;
  createdTime: string;
  orderStatus: number;
  payType: PayType;
  expressNo: string | null;
  expressName: number | null;
}

export enum PayType {
  WX_PAY = 1,
  WALLET = 9
}

export interface PayDataItem {
  payType: PayType;
  money: string;
  openId?: string;
  appId?: string;
}

export interface PayRequestData {
  orderId: number;
  isInMiniprogram: boolean;
  payDetails: PayDataItem[]
}

export interface PayResponseData {
  result: {
    timeStamp: string;
    package: string;
    paySign: string;
    appId: string;
    signType: string;
    nonceStr: string;
  }
}

export const makeFullUrl = (uri: string) => {
  const { protocol, host, pathname } = location;
  return `${protocol}//${host}${pathname}#${uri}`;
}

/**
 * 根据商品数量计算运费, 以及总额
 * @param counts
 */
export const getAmount = (goodsType: IGoodsType[], counts: number[], useFee = true) => {
  const allCount = counts.reduce((sum, n) => sum + n, 0),  // 总数
    fee = allCount > 0 ? allCount * 10 : 0,  // 运费
    total = goodsType.reduce((sum, { price }, i) => sum + price * counts[i], 0) + (useFee ? fee : 0);  // 总额
  return { allCount, fee, total };
};

export const doPay = (param: PayRequestData): Promise<number> => {
  return post('/mooncake/moonCakePay', param).then((res: PayResponseData) => {
    if (res.result) {
      return wechatPaySubmit(res.result, param.orderId, param.isInMiniprogram);
    } else {
      return param.orderId;
    }
  });
}

function checkPayResult(orderId: number, appId: string) {
  return post('/mooncake/queryMoonCakePayStatus', {
    orderId: orderId,
    appId: appId
  }).then(() => {
    return orderId;
  });
}

export const wechatPaySubmit = (payOptions: any, orderId: number, isInMiniprogram = false) => {
  const { WeixinJSBridge, wx } = window as any;
  return new Promise<number>((resolve, reject) => {
    if (isInMiniprogram) {
      wx.miniProgram.navigateTo({
        url: '/module-delivery/pages/mooncakePay/mooncakePay?payOptions=' + encodeURIComponent(JSON.stringify(payOptions)),
      });
      checkPayResult(orderId, payOptions.appId).then(resolve, reject);
    } else {
      WeixinJSBridge.invoke('getBrandWCPayRequest', payOptions, (res: any) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          checkPayResult(orderId, payOptions.appId).then(resolve, reject);
        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
          post('/mooncake/cancelWxPay', { orderId, appId: payOptions.appId }).then(() => {
            reject(new Error('支付取消'));
          }, reject);
          
        } else {
          reject(new Error(JSON.stringify(res)));
        }
      });
    }
  });
};

/**
 * radio 单选组件
 * @param param0
 */
export const RadioGroup = ({ value = '', readOnly = false, onChange = (v: string) => { }, options = {}} ) => <div className="radio-group">
  {Object.entries(options).map(([key, val]) =>
    <a href="javascript:;" key={key} className={key == value ? 'active' : ''} onClick={() => !readOnly && onChange(key)}>{val as string}</a>)}
</div>;


export interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  model: [string, (v: string) => void];
}

export interface FormSelectProps extends ISelectProps {
  model: [string, (v: string) => void];
}

export interface SwitchBtnProps {
  readOnly: boolean;
  model: [boolean, (v: boolean) => void];
}

export const FormInput = ({ model: [value, doInput], ...others }: FormInputProps) =>
  <input {...others} value={value} onChange={(evt) => doInput(evt.target.value)} />;

export const FormSelect = ({ model: [value, doInput], ...others }: FormSelectProps) =>
  <Select {...others} value={value} onChange={(evt) => doInput(evt.target.value)} />;

export const FooterNav = withRouter(({ location }) => {
  const { isInMiniprogram } = useContext(globalContext);
  const orderPage = isInMiniprogram ? '/mooncake/orders' : '/mooncake/search-form';
  return <div className="footer-nav">
    <Link className={location.pathname.indexOf('/buy') > -1 ? 'active' : ''} to={`/mooncake/buy`}>
      <i className="commodity" />
      <span>商品<br /> <span className="en">Commodity</span></span>
    </Link>
    <Link className={location.pathname.indexOf(orderPage) > -1 ? 'active' : ''}  to={orderPage}>
      <i className="orderform" />
      <span>订单<br /> <span className="en">Orderform</span></span>
    </Link>
  </div>;
});

export interface IGlobalContext {
  goodsType: IGoodsType[];
  employeeNumber: string;
  employeeName: string;

  /**
   * 当前用户是否在小程序环境中
   */
  isInMiniprogram: boolean;
  /**
   * 小程序用户的手机号
   */
  userPhone: string;
  /**
   * 小程序用户的openId
   */
  userOpenId: string;
}

export const globalContext = createContext({
  goodsType: [],
  employeeNumber: '',
  employeeName: '',
  userPhone: '',
  userOpenId: '',
  isInMiniprogram: false
} as IGlobalContext);

