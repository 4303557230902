import { createElement as _C } from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import '../resources/style.less';

// import Intro from './intro';
// import Register from './form';
// import PayChannel from './pay';
// import Success from './success';
// import Login from './login';
import MoonCake from './mooncake/index';

document.getElementsByTagName('html')[0].style.fontSize = (window.innerWidth / 375 * 100) + 'px';

const router = <HashRouter>
  <div>
    {/* <Route path="/" exact component={Intro} ></Route>
    <Route path="/register" component={Register}></Route>
    <Route path="/pay" component={PayChannel}></Route>
    <Route path="/success" component={Success}></Route>
    <Route path="/login" component={Login}></Route> */}
    <Route path="/" exact render={() => <Redirect to="/mooncake" />}>
    </Route>
    <Route path="/mooncake" component={MoonCake}></Route>
  </div>
</HashRouter>;

render(router, document.getElementById('app'));

