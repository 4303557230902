import { createElement as _C, FC, useState, useEffect, useRef } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';

const LazyImage: FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ src, ... other }) => {
  const [noLoad, setNoLoad] = useState(true);
  const el = useRef(null as null | HTMLDivElement);
  useEffect(() => {
    if (noLoad) {
      const look = () => {
        if (el.current && el.current.offsetTop <= document.documentElement.scrollTop + window.innerHeight) {
          setNoLoad(false);
          document.removeEventListener('scroll', look);
        }
      };

      document.addEventListener('scroll', look);
      look();
      return () => {
        document.removeEventListener('scroll', look);
      };
    }
  }, [noLoad]);

  if (noLoad) {
    return <div ref={el} {...other} />;
  } else {
    return <img src={src} {... other} />;
  }
}

export const IntroPage: FC<RouteChildrenProps> = ({ history }) => {

  const jump = () => history.push('/mooncake/buy');

  return <div className="intro-page">
    <div onClick={jump} >
      <img src="https://bw-qiniu.beeplus.xyz/mooncake/1.jpg" style={{ height: '6.13rem'}} />
      <img src="https://bw-qiniu.beeplus.xyz/mooncake/2.jpg" style={{ height: '4.85rem'}} />
      <img src="https://bw-qiniu.beeplus.xyz/mooncake/3.jpg" style={{ height: '5.06rem'}} />
      <img src="https://bw-qiniu.beeplus.xyz/mooncake/4.jpg" style={{ height: '4.65rem'}} />
    </div>
    <Link className="order-btn" to="/mooncake/orders"></Link>

    <Link className="btn1" style={{ top: '3.81rem', left: '.3rem' }} to="/mooncake/buy"></Link>
    <Link className="btn1" style={{ top: '4.58rem', right: '.32rem' }} to="/mooncake/buy"></Link>
    <Link className="btn1" style={{ top: '5.41rem', left: '.35rem' }} to="/mooncake/buy"></Link>

    <Link className="btn2" style={{ top: '7.72rem', left: '.37rem' }} to="/mooncake/buy"></Link>
    <Link className="btn2" style={{ top: '12.22rem', left: '.37rem' }} to="/mooncake/buy"></Link>
    <Link className="btn2" style={{ top: '18.37rem', left: '.37rem' }} to="/mooncake/buy"></Link>

    <div className="mask" />

    <a href="javascript:;" className="go-top-btn" onClick={(evt) => {
      evt.stopPropagation();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      // document.documentElement.scrollTo(0, 0);
    }}>
      <i />
      回到顶部
    </a>
  </div>
};
