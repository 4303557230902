import { createElement as _C, useState, useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router";
import {
  Row,
  Col,
  parseQueryString,
  defineRequest,
  useLoading,
  APP_ID,
} from "../common";
import SwipeableViews from "react-swipeable-views";
import { globalContext, doPay, getAmount, OrderData, PayType } from "./common";

const useOrdersRequest = defineRequest<
  { phone: string },
  { result: OrderData[] }
>("/mooncake/queryMoonCakeOrder");

const OrderStatus: { [key: string]: string } = {
  "1": "待支付",
  "2": "待发货",
  "3": "已发货",
  "4": "待取货",
  "5": "已完成",
  "6": '已退款',
  "7": '已完成'
};

const ExpressType: { [key: string]: string } = {
  '0': '圆通快递',
  '1': '顺丰快递',
  '2': '货拉拉',
  '3': '滴滴'
};

function phoneFormat(tel: string) {
  return [tel.slice(0, 3), tel.slice(3, 7), tel.slice(7)].join(' ');
}

export const OrdersPage = ({ location, history }: RouteComponentProps) => {
  const { isInMiniprogram, userPhone, userOpenId, goodsType } = useContext(
    globalContext
  );
  const {
    phone = userPhone,
    orderId = "",
    openid = userOpenId,
  } = parseQueryString(location.search);

  const [{ isLoading, data, error }, request] = useOrdersRequest();
  const [index, setIndex] = useState(0);

  useEffect(() => request({ phone }), [0]);
  let content;

  if (isLoading || !data) {
    content = <div className="loading">正在加载中...</div>;
  } else if (error) {
    content = <div className="error">{error.message}</div>;
  } else if (data != null && data.result != null && data.result.length > 0) {
    content = (
      <div>
        <SwipeableViews onChangeIndex={(i) => setIndex(i)} index={index}>
          {data.result.map((item, i) => {

            const pay = useLoading(() => {
              return doPay({
                orderId: item.orderId,
                isInMiniprogram,
                payDetails: [{
                  payType: PayType.WX_PAY,
                  openId: userOpenId,
                  appId: APP_ID,
                  money: item.totalMoney.toFixed(2)
                }]
              }).then(() =>
                history.push(
                  `/mooncake/success-page?orderId=${orderId}&phone=${phone}&openid=${openid}`
                )
              );
            });

            const gotoForm = () => {
              const { moonCakeDetails, ... others } = item;
              const counts = goodsType.map((g) => moonCakeDetails.find((i) => i.goodId === g.id)?.quantity || 0);
              history.push(`/mooncake/form?counts=${encodeURIComponent(JSON.stringify(counts))}&order=${encodeURIComponent(JSON.stringify(others))}`);
            };

            return (
              <div key={i} className="order-item">
                <div className="title">
                  订单详情{" "}
                  <span className={"order-status status-" + item.orderStatus}>
                    {OrderStatus[item.orderStatus.toString()] || ""}
                  </span>
                </div>
                <div className="item-list">
                  {item.moonCakeDetails
                    .map((goods, i) => {
                      const type = goodsType.find(
                        ({ id }) => goods.goodId === id
                      );
                      // const goods = item.moonCakeDetails.find(({ shopName }) => shopName == type.name);
                      return {
                        ...type!,
                        count: goods!.quantity || 0,
                        price: goods!.unitPrice || 0,
                      };
                    })
                    .map((item, i) => (
                      <Row className="item" key={i}>
                        <img className="goods" src={item.imgs[1]} />
                        <Col className="info">
                          <h1>
                            {item.name}{" "}
                            <span className="price">￥ {item.price}</span>
                          </h1>
                          <div className="count">X {item.count}</div>
                        </Col>
                      </Row>
                    ))}
                </div>
                <div className="amount-info">
                  {(item.payType === PayType.WALLET || item.payType === 8)  && (
                    <span className="fee">钱包支付</span>
                  )}
                  {item.isSelf == 0 && (
                    <span className="fee">
                      运费: ￥
                      {
                        getAmount(
                          goodsType,
                          item.moonCakeDetails.map(({ quantity }) => quantity)
                        ).fee
                      }
                    </span>
                  )}
                  <span className="total-label">合计:</span>
                  <span className="total-price">￥{item.totalMoney}</span>
                </div>
                <div className="info-area">
                  <div className="item">
                    <label>编号</label>
                    <span className="txt">{item.orderNo}</span>
                  </div>
                  <div className="item">
                    <label>购买日期</label>
                    <span className="txt">{item.createdTime}</span>
                  </div>
                  <div className="item">
                    <label>取货方式</label>
                    <span className="txt">
                      {item.isSelf > 0 ? "到店自取" : "快递"}
                    </span>
                  </div>
                  {item.moonCakeAddress != null && (
                    <div className="item">
                      <label>收件人</label>
                      <span className="txt">
                        {item.moonCakeAddress.consignee}
                      </span>
                    </div>
                  )}
                  {item.moonCakeAddress != null && (
                    <div className="item">
                      <label>手机号码</label>
                      <span className="txt">{phoneFormat(item.moonCakeAddress.tel)}</span>
                    </div>
                  )}
                  {item.moonCakeAddress != null && (
                    <div className="item">
                      <label>收货地址</label>
                      <span className="txt">
                        {item.moonCakeAddress.address}
                      </span>
                    </div>
                  )}
                  {item.moonCakeAddress != null && <div className="item">
                    <label>快递号</label>
                    {item.expressNo === null || item.expressName === null ? (
                      <span className="txt"> -- </span>
                    ) : (
                      <span className="txt">
                        {ExpressType[item.expressName]} {item.expressNo}
                      </span>
                    )}
                  </div>}

                  {item.isSelf !== 0 && (
                    <div className="item">
                      <label>取货地址</label>
                      <span className="txt">
                        深圳市南山区海德二道
                        BEEPLUS 超级工坊二楼甜品站
                      </span>
                    </div>
                  )}
                </div>

                <div className="operation-area">
                  <a href="tel://400-888-7000" className="contact">
                    联系客服
                  </a>
                  {item.orderStatus == 1 && (
                    <button className="submit" onClick={gotoForm}>
                      去支付
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </SwipeableViews>

        {data.result.length > 1 && <div className="page-signs">
          {data.result.map((_, i) => (
            <span key={i} className={i == index ? "active" : ""} />
          ))}
        </div>}
      </div>
    );
  } else {
    content = <div className="error">暂无订单</div>;
  }

  return <div className="orders-page">{content}</div>;
};
