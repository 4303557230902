import { createElement as _C, useState, useContext } from 'react';
import { Row, Col, doAuth, alert } from '../common';
import { createPortal } from 'react-dom';
import { getAmount, globalContext, FooterNav, IGoodsType, makeFullUrl } from './common';
import { withRouter } from 'react-router';

/**
 * 生成微信授权的回调地址
 * @param param0
 */
const getResultUrl = ({
  third = 'false',
  employee = '',
  counts = new Array<number>()
}) => `/mooncake/form?employee=${employee}&counts=${encodeURIComponent(JSON.stringify(counts))}`;

/**
 * 商品详情弹窗
 * @param param0
 */
const GoodsDetail = ({ src = '', onCancel = () => {} }) => createPortal(<div className="goods-mask" onClick={() => onCancel()}>
  <img src={src} onClick={(evt) => evt.stopPropagation()} />
  <a href="javascript:;" className="close" onClick={() => onCancel()}>X</a>
</div>, document.body);

/**
 * 可点击弹出详情的商品图片
 * @param param0
 */
const GoodsImg = ({ imgs, over = false, hurry = false  }: {imgs: string[], over: boolean, hurry : boolean }) => {
  // const [isShow, setShow] = useState(false);
  return <div className="goods">
    {/* {over && <div className="mask">已售罄</div>}
    {hurry && <div className="tag">LAST SALE</div>} */}
    {/* <div className="mask">已售罄</div> */}
    <img src={imgs[0]} />
    {/* {isShow && <GoodsDetail src={imgs[1]} onCancel={() => setShow(false)} />} */}
  </div>;
}

/**
 * 商品选择状态控制
 */
const useGoodsStatus = (goodsType: IGoodsType[]): [number[], (c: number, i: number) => void] => {
  const [state, setState] = useState(goodsType.map(() => 0));
  const _setState = (count: number, i: number) => {
    setState(state.map((v, j) => i == j ? Math.max(0, count) : v));
  }
  return [state, _setState];
}

/**
 * 数量选择组件
 * @param param0
 */
const NumBtn = ({ value = 0, onChange = (v: number) => { } }) => {
  const [val, setVal] = useState({ val: '', edit: false });
  return <Row className="num-btn">
    {value > 0 && <a href="javascript:;" className="subtract" onClick={() => onChange(value - 1)}>
      <img src={require('../../resources/img/reduce.svg')} />
    </a>}
    {value > 0 && <input className="amount" value={val.edit ? val.val : value}
      type="number"
      onFocus={() => setVal({ val: value.toString(), edit: true })}
      onChange={(evt) => setVal({ val: evt.target.value, edit: true })}
      onBlur={() => {
        setVal({ val: '', edit: false });
        onChange(Math.max(1, parseInt(val.val, 10) || 0));
      }} />}
    <a href="javascript:;" className="add" onClick={() => onChange(value + 1)}>
      <img src={require('../../resources/img/add2.svg')} />
    </a>
  </Row>
};

/**
 * 选择商品页面
 * @param param0
 */
export const BuyPage = withRouter(({ history }) => {
  const { isInMiniprogram, employeeNumber = '', employeeName = '', goodsType, userOpenId } = useContext(globalContext);
  const [counts, setCounts] = useGoodsStatus(goodsType);

  const { total, allCount } = getAmount(goodsType, counts, false);

  return <div className="buy-page">
    <div className="card-item-list">
      {goodsType.filter((g) => g.isValid).map((item, i) => {
        const isOver = false;
        const isHurry = false;
        return <div className={"item" + (isOver ? ' over' : '')} key={i}>
          <GoodsImg imgs={item.imgs} over={isOver} hurry={isHurry} />
          <div className="info">
            <div className="title">{item.name}</div>
            <div className="desc">{item.description}</div>
            <div className="bot" >
              {isInMiniprogram ?
                <span className="price">￥{item.originPrice}</span> :
                <span className="price">￥{item.vipPrice}<span className="en">/{item.originPrice}</span></span>}
              {!isOver && <NumBtn value={counts[i]} onChange={(v) => {
                setCounts(v, i);
                // alert('已售罄!');
              }} />}
            </div>
          </div>
        </div>
      })}
    </div>
    <div className="footer">
      {/* <Row className="first">
        <div className="package">
          <img src={require('../../resources/img/mooncake-package.png')} />
          <span className="count">{allCount}</span>
        </div>
        <div className="amount-info">
          <span className="total-label">合计:</span>
          <span className="total-price">￥{total.toFixed(2)}</span>
          <button className="submit" onClick={() => {
            if (allCount > 0) {
              const path = getResultUrl({ employee: employeeNumber, counts });
              if (userOpenId) {
                history.push(path);
              } else {
                doAuth(makeFullUrl(path));
              }
            } else {
              alert('您还没有选择任何商品');
            }
          }}>结算</button>
        </div>
      </Row> */}
       <div className="settlement_box">
        <div className="settlement">
          <div className="total">
            <span>{allCount}</span>
            <img src={require('../../resources/img/total.png')} />
          </div>
          <div className="amount_of_money">
              <span>￥</span>{ total.toFixed(2)}
          </div>
        </div>
        <div className="check"  onClick={() => {
          alert('商品已售罄!');
            // if (allCount > 0) {
            //   const path = getResultUrl({ employee: employeeNumber, counts });
            //   if (userOpenId) {
            //     history.push(path);
            //   } else {
            //     doAuth(makeFullUrl(path));
            //   }
            // } else {
            //   alert('您还没有选择任何商品');
            // }
          }}>去结算</div>
      </div>
      <FooterNav  />
    </div>
  </div>;
});
