import { createElement as _C, useState, useEffect, useContext } from 'react';
import { Row, Col, doAuth, parseQueryString, defineRequest, alert, post, IRequestDataState, wechatPaySubmit, useLoading, wrapUseState, APP_ID, MINI_APP_ID } from '../common';
import { PhoneField, checkPhoneFormat } from '../form';
import { RouteComponentProps } from 'react-router';
import { History } from 'history';
import { globalContext, FormInput, RadioGroup, FormSelect, getAmount, FormData, doPay, PayType, SwitchBtnProps, PayDataItem, OrderData } from './common';

const assert = require('assert');

const getBalance = async (phone: string) => post('/mooncake/queryBalance', { phone }).then(({ result }) => result);

interface FormExtraData {
  useBalance: number;
  authCode: string;
  isInMiniprogram: boolean;
}

/**
 * 发送
 */
const submitFormData = async (formData: FormData & FormExtraData, history: History) => {
  const { authCode, ... data } = formData;
  checkPhoneFormat(data.phone, '请输入您的正确手机号码');

  if (!formData.isInMiniprogram) {
    assert(authCode != null, '验证码不可为空');
  }

  if (data.isSelf == 0) {
    if (data.moonCakeAddress == null) {
      throw new Error('必须要有moonCakeAddress');
    }
    assert(data.moonCakeAddress.address.length > 0, '收货地址不可为空');
    assert(data.moonCakeAddress.consignee.length > 0, '收货人不可为空');
    checkPhoneFormat(data.moonCakeAddress.tel, '请输入正确的收货人手机号码');
  }

  const orderId = (await post('/mooncake/addMoonCakeOrder', {
    ... data,
    orderSource: data.isInMiniprogram ? 2 : 1,
  })).result;

  await pay({
    orderId,
    totalMoney: formData.totalMoney,
    useBalance: formData.useBalance,
    openId: formData.openId,
    isInMiniprogram: formData.isInMiniprogram,
    phone: data.phone
  }, history);
};



const pay = async ({ orderId = 0, totalMoney = 0, useBalance = 0, isInMiniprogram = false, openId = '', phone = '' }, history: History): Promise<void> => {
  const payDetails: PayDataItem[] = [];
  if (useBalance > 0) {
    payDetails.push({
      payType: PayType.WALLET,
      money: useBalance.toFixed(2)
    });
  }
  if (totalMoney - useBalance > 0) {
    payDetails.push({
      payType: PayType.WX_PAY,
      money: (totalMoney - useBalance).toFixed(2),
      appId: isInMiniprogram ? MINI_APP_ID : APP_ID,
      openId: openId
    });
  }
  try {
    await doPay({
      isInMiniprogram,
      orderId,
      payDetails,
    });
  } finally {
    history.push(`/mooncake/orders?orderId=${orderId}${phone ? '&phone=' + phone : ''}`);
  }
}

/**
 * 切换按钮
 */
const SwitchBtn = ({ model: [value, doInput], readOnly = false }: SwitchBtnProps) => {
  return <div className={"switch-btn " + (value ? 'active' : '')} onClick={() => !readOnly && doInput(!value)}>
    <div className="bar" />
    <div className="btn" />
  </div>;
}

/**
 * 月饼订单表单
 */
export const FormPage = ({ location, history }: RouteComponentProps) => {
  const { userOpenId, goodsType, employeeNumber, userPhone, isInMiniprogram } = useContext(globalContext);
  const {
    counts = encodeURIComponent('[]'),
    order,
    openid = userOpenId
  } = parseQueryString(location.search);

  const originOrder = order ? JSON.parse(decodeURIComponent(order)) as OrderData : null;
  // console.log(order);
  const readOnly = !!originOrder;

  // const today = now();
  const address = useState(originOrder?.moonCakeAddress?.address || ''),
    consignee = useState(originOrder?.moonCakeAddress?.consignee || ''),
    userName = useState(originOrder?.userName || ''),
    authCode = useState(''),
    phone = useState(originOrder?.phone || userPhone || ''),
    tel = useState(originOrder?.moonCakeAddress?.tel || (isInMiniprogram ? userPhone : '')),
    remark = useState(originOrder?.remark || ''),
    // sendTime = useState(dates[0]),
    isSelf = useState(originOrder?.isSelf === undefined ? 0 : originOrder.isSelf),
    [balance, setBalance] = useState(0),
    [isUsingBalance, setUsingBalance] = useState(false),
    buyForOther = useState(isInMiniprogram),
    [hasCheckCode, setCheckCode] = useState(isInMiniprogram || !!originOrder?.phone);

  const countsArr = JSON.parse(decodeURIComponent(counts)) as number[];
  const { total, fee } = getAmount(goodsType, countsArr, isSelf[0] == 0);

  const checkAuthCode = () => {
    if (phone[0] && authCode[0]) {
      post('/mooncake/checkMsgCode', { phone: phone[0], code: authCode[0] }).then(() => {
        setCheckCode(true);
      }).catch(() => {
        alert('请输入正确的验证码');
      });
    }
  };

  const submit = useLoading(() => {
    const totalMoney = Math.round(total * 100) / 100;
    const useBalance = isUsingBalance ? Math.min(balance, totalMoney) : 0;
    if (!hasCheckCode) {
      return Promise.resolve();
    } else if (!readOnly) {
      return submitFormData({
        openId: openid,
        isSelf: isSelf[0],
        authCode: authCode[0],
        isInMiniprogram,
        // deliveryTime: sendTime[0],
        useBalance,
        referencePhone: employeeNumber,
        phone: phone[0],
        remark: remark[0],
        userName: userName[0],
        totalMoney,
        moonCakeDetails: goodsType.map(({ price, name, id }, i) => ({
          goodId: id,
          unitPrice: price,
          shopName: name,
          quantity: countsArr[i] || 0
        })).filter(({ quantity }) => quantity != 0),
        moonCakeAddress: isSelf[0] == 0 ? {
          consignee: buyForOther[0] ? consignee[0] : userName[0],
          address: address[0],
          tel: buyForOther[0] ? tel[0] : phone[0]
        } : null
      }, history);
    } else {
      return pay({
        openId: openid,
        orderId: originOrder?.orderId,
        totalMoney,
        useBalance,
        isInMiniprogram,
        phone: originOrder?.phone,
      }, history);
    }
  });

  useEffect(() => {
    // 在小程序环境中, 初始化时加载用户余额
    if (hasCheckCode) {
      getBalance(phone[0]).then((res) => {
        setBalance(res);
        if (res > 0) {
          setUsingBalance(true);
        }
      }).catch(console.error);
    }
  }, [hasCheckCode]);

  return <div className="form-page">
    <div className="item-list">
      {goodsType.map((item, i) => ({ ...item, count: countsArr[i] || 0 }))
        .filter(({ count }) => count > 0)
        .map((item, i) => <Row className="item" key={i}>
        <img className="goods" src={item.imgs[1]} />
        <Col className="info">
          <h1>{item.name} <span className="price">￥{item.price}</span></h1>
          <div className="count">X {item.count}</div>
        </Col>
      </Row>)}
      <div className="amount-info">
        {isSelf[0] == 0 &&  <span className="fee">运费: ￥{fee}</span>}
        <span className="total-label">小计:</span>
        <span className="total-price">￥{total.toFixed(2)}</span>
      </div>
    </div>

    {!isInMiniprogram && <div className="form-area">
      <h1>购买人信息</h1>
      <div className="form-item">
        <label>姓名</label>
        <FormInput model={userName} readOnly={readOnly} placeholder="请输入姓名" />
      </div>
      <div className="form-item">
        <label>手机号</label>
        {/* <FormInput model={phone} /> */}
        <PhoneField readOnly={hasCheckCode || readOnly} value={phone[0]} placeholder="请输入手机号码" 
          checkPhone={async () => hasCheckCode && Promise.reject(new Error('已完成验证，无需重复验证'))} 
          onChange={(value: any) => phone[1](value)} />
      </div>
      <div className="form-item">
        <label>验证码</label>
        <FormInput model={authCode} readOnly={hasCheckCode || readOnly} placeholder="请输入验证码" maxLength={6} onBlur={!hasCheckCode ? checkAuthCode : undefined} />
      </div>
    </div>}

    <div className="form-area">
      <div className="form-item">
        <label>取货方式</label>
        <RadioGroup readOnly={readOnly} value={isSelf[0].toString()} onChange={(val) => isSelf[1](parseInt(val, 10))}
          options={{ '0': '快递', '1': '到店自取' }}
        />
      </div>
    </div>

    {isSelf[0] == 0 && <div className="form-area">
      <h1>收货人信息</h1>
      {!isInMiniprogram && <div className="form-item label1">
        <label>是否替他人购买</label>
        <SwitchBtn readOnly={readOnly} model={buyForOther} />
      </div>}
      {buyForOther[0] && <div className="form-item">
        <label>姓名</label>
        <FormInput readOnly={readOnly} model={consignee} placeholder="请输入收件人姓名"/>
      </div>}
      {buyForOther[0] && <div className="form-item">
        <label>手机号码</label>
        <FormInput readOnly={readOnly} model={tel} placeholder="请输入收件人手机号码" />
      </div>}
      <div className="form-item">
        <label>收货地址</label>
        <FormInput readOnly={readOnly} model={address} placeholder="请输入收件人地址" />
      </div>
      {/* <div className="form-item">
        <label>发货时间</label>
        <FormSelect model={sendTime} options={dates.reduce((op, d) => ({ ...op, [d]: d }) , {})} />
      </div> */}
      <div className="form-item">
        <label>备注</label>
        <FormInput readOnly={readOnly} model={remark} placeholder="请填写备注信息(可选)" />
      </div>
    </div>}

    {isSelf[0] != 0 && <div className="form-area">
      <h1>到店自取</h1>
      <div className="form-item">
        <label>提货地点</label>
        <span className="txt">
          深圳市南山区海德二道 <br />
          BEEPLUS 超级烘焙工坊二楼甜品站
        </span>
      </div>
      {/* <div className="form-item">
        <label>提货日期</label>
        <span className="txt">8月27日后</span>
      </div>
      <div className="form-item">
        <label>提货时间</label>
        <span className="txt">10：00-22：00</span>
      </div> */}
    </div>}

    {hasCheckCode && <div className="form-area">
      <div className="form-item wallet-item">
        <label>BEEPLUS 钱包支付</label>
        <div className="balance-area">
          <div className="radio-group">
            <a href="javascript:;" className={isUsingBalance ? 'active' : ''} onClick={() => setUsingBalance(!isUsingBalance)}>￥{balance}</a>
          </div>
        </div>
      </div>
    </div>}

    {<div className="dialog-wrap">
      <div className="dialog">

      </div>
    </div>}

    <a href="javascript:;" className={"submit-btn " + (!hasCheckCode ? 'disabled' : '')} onClick={hasCheckCode ? submit : undefined}>立即支付</a>
  </div>;
}
