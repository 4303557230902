import { createElement as _C } from 'react';
import { RouteComponentProps } from 'react-router';
import { parseQueryString } from '../common';


/**
 * 支付成功页面
 * @param param0
 */
export const SuccessPage = ({ history, location }: RouteComponentProps) => {
  const { openid, phone, orderId } = parseQueryString(location.search);
  return <div className="mooncake-success-page">
    <div className="sign">
      <img src={require('../../resources/img/success.svg')} />
      <span>支付成功!</span>
    </div>
    <a className="submit-btn" href="javascript:;" onClick={() => history.push(`/mooncake/orders?orderId=${orderId}&phone=${phone}&openid=${openid}`)}>回到订单页面</a>
  </div>;
}
