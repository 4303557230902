import { createElement as _C, useState, useContext } from 'react';
import { doAuth, post, useLoading } from '../common';
import { PhoneField, checkPhoneFormat } from '../form';
import { FormInput, FooterNav, makeFullUrl, globalContext } from './common';
import { RouteComponentProps } from 'react-router';


const assert = require('assert');

const doSearch = async ({ phone = '', code = '' }) => {
  checkPhoneFormat(phone);
  assert(code != '', '验证码不可为空');
  return post('/mooncake/checkMsgCode', { phone, code });
};

export const SearchForm = ({ history }: RouteComponentProps) => {
  const { isInMiniprogram } = useContext(globalContext);
  const phone = useState(''),
    authCode = useState('');

  const submit = useLoading(() => {
    return doSearch({ code: authCode[0], phone: phone[0] })
      .then(() => post('/mooncake/queryMoonCakeOrder', { phone: phone[0] }).then(({ result }) => {
        if (!Array.isArray(result) || result.length == 0) {
          throw Error('没有查询到对应订单');
        }
      }))
      .then(() => {
        if (isInMiniprogram) {
          history.push('/mooncake/orders');
        } else {
          doAuth(makeFullUrl(`/mooncake/orders?phone=${phone[0]}`));
        }
      });
  });

  return <div className="search-form">
    <img className="bg" src={require('../../resources/img/bg.svg')} />
    <div className="form-area">
      <h1>订单查询</h1>
      {/* <div className="form-item">
        <label>姓名</label>
        <FormInput model={userName} placeholder="请输入姓名" />
      </div> */}
      <img className="decorate-person" src={require('../../resources/img/search-form-deco.png')} />
      <div className="form-item">
        <label>手机号码</label>
        <PhoneField value={phone[0]} onChange={(val: any) => phone[1](val)} placeholder="请输入手机号码" />
      </div>
      <div className="form-item">
        <label>验证码</label>
        <FormInput model={authCode} maxLength={6} placeholder="请输入验证码" />
      </div>
    </div>
    <a href="javascript:;" className="submit-btn" onClick={submit}>立即查询</a>
    <div className="custorm-info">
      <div className="with-line">如您对订单有任何疑问，请联系我们的客服</div>
      <div>400-888-7000</div>
    </div>
    <FooterNav />
  </div>;
}
