import { createElement as _C, ReactNode, ReactElement, useState } from 'react';
import { render } from 'react-dom';
import { observable, action } from 'mobx';
import 'whatwg-fetch';

function _create(sign: string) {
  return ({ className = '', center = false, ... others }) => {
    return <div className={`flex-view ${sign} ${center ? 'center' : ''} ${className}`} {... others}>{others.children}</div>;
  }
}

export const Row = _create('row');

export const Col = _create('col');

export const Icon = ({ type = '', size = 30 }) => <i className={"icon " + type} style={{ width: size, height: size, backgroundSize: size }}></i>;

export const APP_ID = 'wx78a5a8f01c92de7a';

export const MINI_APP_ID = 'wx54fc85abfa173b51';

/**
 * 倒计时工具
 */
export const countdown = observable({

  _d: new Date().getTime(),

  get now() {
    return this._d;
  }
});

setInterval(action(() => countdown._d = new Date().getTime()), 1000);

export function useLoading <P, R> (func: (p: P) => Promise<R>): (p: P) => Promise<R | void> {
  let loading: HTMLElement | null = null;
  return (p: P): Promise<R | void> => {
    if (loading != null) {
      return Promise.reject(new Error('不能重复提交'));
    }
    loading = startLoading();
    return func(p).then((res) => {
      loading && loading.remove();
      loading = null;
      return res;
    }).catch((err) => {
      loading && loading.remove();
      loading = null;
      alert(err.message);
      console.error(err);
      throw err;
    });
  }
}

export function alert(message: string) {
  const div = document.createElement('div');
  div.className = 'fixed-alert';
  div.innerHTML = '<div class="prompt">' + message + '</div>';
  document.body.appendChild(div);
  setTimeout(() => div.remove(), 2000);
}

export function startLoading() {
  const div = document.createElement('div');
  div.className = 'fixed-mask';
  document.body.appendChild(div);
  render(<div className="loading"><img src={require('../resources/img/loading.svg')} /></div>, div);
  return div;
}

export function parseQueryString(str: string): { [key: string]: string } {
  return str.split(/\?|\&/).map((s) => /^(\w+)=([^\s\:\/\?\*\&\=]*)$/.exec(s))
    .filter((m) => m != null)
    .reduce((obj, m: any) => ({ ... obj, [m[1]]: m[2] }), {});
}

/**
 * 暂停执行数秒
 * @param seconds
 */
export function sleep(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

/**
 * 进行微信授权
 */
export function doAuth(back_uri: string) {
  const redirect_uri = location.protocol + '//' + location.host + '/data-api/mooncake/wechatAuthCallback';
  location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${encodeURIComponent(back_uri)}#wechat_redirect`;
}

/**
 * 呼出微信支付弹窗
 * @param payOptions
 */
export const wechatPaySubmit = (payOptions: any, isInMiniprogram = false) => {
  const { WeixinJSBridge, wx } = window as any;
  return timeout(new Promise((resolve, reject) => {
    if (isInMiniprogram) {
      wx.miniProgram.navigateTo({
        url: '/module-delivery/pages/mooncakePay/mooncakePay?payOptions=' + encodeURIComponent(JSON.stringify(payOptions)),
      });
    } else {
      WeixinJSBridge.invoke('getBrandWCPayRequest', payOptions, (res: any) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          resolve();
        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
          reject(new Error('支付取消'));
        } else {
          // console.error(res);
          // alert();
          reject(new Error(JSON.stringify(res)));
        }
      });
    }
  }), 60 * 1000);
};

export function timeout <T> (promise: Promise<T>, ms: number = 5000) {
  return new Promise<T>(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error("处理超时"))
    }, ms)
    promise.then(resolve, reject)
  })
}

/**
 * 发送http 请求
 * @param uri
 * @param data
 * @param opt
 */
export function post(uri: string, data = {}, opt: any = {}): Promise<any> {
  const { method } = opt;
  return fetch('/data-api' + uri, {
    body: method === 'GET' ? undefined : JSON.stringify(data), // must match 'Content-Type' header
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'content-type': 'application/json',
    },
    method: method || 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else if (res.status === 404) {
      return Promise.reject(new Error('页面不存在'));
    } else {
      return res.json().then((resData) => Promise.reject(new Error(resData.message)));
    }
  }).then(res => {
    if (opt.transform) {
      res = opt.transform(res);
    }
    if (res.code != 200) {
      return Promise.reject(new Error(res.message));
    }
    return res;
  }) ;
}

export function get(uri: string, data = {}) {
  return post(uri, data, { method: 'GET' });
}

export interface IRequestDataState<R> {
  isLoading: boolean;
  data: R | null;
  error: Error | null;
}

export function wrapUseState <P = void, R = {}>(func: (p: P) => Promise<R>) {
  return (): [IRequestDataState<R>, (p: P) => void] => {
    const [state, setState] = useState({ isLoading: false, data: null, error: null } as IRequestDataState<R>);

    const request = (params: P) => {
      if (state.isLoading) {
        return
      }
      setState({ ... state, isLoading: true });
      func(params).then((data) => setState({ error: null, isLoading: false, data: data }))
        .catch((error) => setState({ error, data: null, isLoading: false }))
    }

    return [state, request];
  };
}

export function defineRequest<P, R>(uri: string) {
  return wrapUseState((params: P) => post(uri, params).then((res) => res as R));
}
