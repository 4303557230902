import { createElement as _C,  useEffect, useState } from 'react';
import { Route, RouteComponentProps} from 'react-router';
import { globalContext, IGlobalContext, IGoodsType } from './common';
import { parseQueryString, post, APP_ID, get, alert } from '../common';
import { LinkCreator } from './link-creator';
import { BuyPage } from './buy-page';
import { FormPage } from './form-page';
import { OrdersPage } from './orders-page';
import { SearchForm } from './search-form';
import { SuccessPage } from './success-page';
import { IntroPage } from './intro-page';


/**
 * 微信配置
 * @param win
 * @param param1
 * @param cb
 */
function wxconfig(win: any, { noncestr = '', timestamp = '', signature = '' }, cb: (wx: any) => void) {
  const wx = win.wx;
  wx.config({
    debug: false,
    appId: APP_ID,
    timestamp,
    nonceStr: noncestr,
    signature,
    jsApiList: [
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'onMenuShareQQ',
      'onMenuShareWeibo',
      'onMenuShareQZone',
      'showMenuItems',
      'hideMenuItems'
    ]
  });

  wx.ready(() => {
    // alert('微信初始化完成');
    cb(wx);
  });

  wx.error(function(){
    console.log('微信验证失败');
    // cb(wx);
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  });
}


/**
 * 初始化微信设置
 */
const initWXsetting = async (win: any, { employeeName = '' }: any) => {
  if (win.wx) {
    const noncestr = 'WX_'+ Math.random();
    const timestamp = new Date().getTime().toString();
    const i = location.href.indexOf('#');
    const url = i > -1 ? location.href.slice(0, i) : location.href;
    const { result } = await post(`/mooncake/getWXticket`, { noncestr, timestamp, url });
    wxconfig(win, { noncestr, timestamp, signature: result }, (wx) => {
      const op = {
        title: 'BEEPLUS 中秋月饼',
        desc: `您的好友向您推荐了一款月饼`,
        link: location.href,
        imgUrl: 'https://bw-qiniu.beeplus.xyz/233_icon.png',
        success: function () {
            alert('已分享');
        },
        trigger: function (res: any) {
          // console.log(res);
          // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
          // alert('用户点击分享到朋友圈');
          // return false;
          //throw new Error('fdsfsd');
        },
        cancel: function () {
            // alert('已取消');
        },
        fail: function () {
            // alert(JSON.stringify(res));
        }
      };

      wx.onMenuShareAppMessage(op);
      wx.onMenuShareTimeline(op);
      wx.onMenuShareQQ(op);
      wx.onMenuShareWeibo(op);
      wx.onMenuShareQZone(op);
    });
  }
}

/**
 * 加载商品数据
 */
const getGoods = async (isInMiniprogram: boolean): Promise<IGoodsType[]> => {
  // 先尝试读缓存
  // const goods = window.localStorage.getItem('goodsType');
  // if (goods) {
  //   return JSON.parse(goods);
  // }

  const { result } = await get('/mooncake/queryAllMoonCakeGood');
  const allGoodsType = result
    // .filter((p: any) => p.isValid)
    .map((p: any) => ({
      name: p.goodName || '',
      description: p.description || '',
      price: isInMiniprogram ? p.purchasePrice : p.discountPrice,
      vipPrice: p.discountPrice || 0,
      originPrice: p.purchasePrice || 0,
      id: p.id,
      isValid: p.isValid,
      imgs: JSON.parse(p.imageUrl || '[]')
    }));

  window.localStorage.setItem('goodsType', JSON.stringify(allGoodsType));

  return allGoodsType;
}

// const initGoods = async (params: any) => {
//   await initWXsetting(window, params).catch((err) => console.error(err));
//   return await getGoods();
// };

const checkEnv = async () => new Promise<boolean>((resolve, reject) => {
  const win: any = window;
  win.wx.miniProgram.getEnv(({ miniprogram = false }) => {
    resolve(miniprogram);
  });
});

const init = async (params: any): Promise<IGlobalContext> => {
  const isInMiniprogram = await checkEnv();
  const openIdKey = (isInMiniprogram ? 'mini' : 'h5' ) + '_openId';
  const {
    employeeName = localStorage.getItem('employeeName') || '',
    employee = localStorage.getItem('employeeNumber') || '',
    userPhone = '',
    userOpenId = localStorage.getItem(openIdKey) || ''
  } = params;

  // 小程序环境中不对 jssdk 做初始化
  if (!isInMiniprogram) {
    await initWXsetting(window, { employeeName }).catch((err) => console.error(err));
  }

  const en = decodeURIComponent(employeeName);

  const goodsTypes = await getGoods(isInMiniprogram);
  if (!isInMiniprogram) {
    localStorage.setItem('employeeName', employeeName);
    localStorage.setItem('employeeNumber', employee);
  }

  localStorage.setItem(openIdKey, userOpenId);
  return {
    goodsType: goodsTypes,
    employeeName: isInMiniprogram ? '' : en,
    employeeNumber: isInMiniprogram ? '' : employee,
    userPhone,
    userOpenId,
    isInMiniprogram,
  };
}

/**
 * 根据情况隐藏或显示微信分享按钮
 * @param window
 * @param param1
 */
const hideOrShowMenuBtns = (window: any, pathname: string) => {
  const wx = window.wx;
  if (wx) {
    const op = { menuList: [
      'menuItem:share:appMessage',
      'menuItem:share:timeline',
      'menuItem:share:qq',
      'menuItem:share:weiboApp',
      'menuItem:share:facebook',
      'menuItem:share:QZone'
    ] };
    if (pathname.indexOf('/buy') > -1 || /mooncake$/.test(pathname)) {
      console.log('open shared');
      wx.showMenuItems(op);
    } else {
      wx.hideMenuItems(op);
    }
  }
}

const InnerRoute = (props: RouteComponentProps) => {
  const { match, location } = props;
  const [global, setGlobal] = useState(null as IGlobalContext | null);

  // 检查当前环境, 以及设置全局数据
  useEffect(() => {
    init(parseQueryString(location.search))
    .then(setGlobal)
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
  }, [0]);

  useEffect(() => {
    document.title = 'BEEPLUS中秋月饼';
    if (global && !global.isInMiniprogram) {
      hideOrShowMenuBtns(window, location.pathname);
    }
    // request();
  }, [location.pathname, global?.isInMiniprogram || false]);

  // 在小程序环境中, 每次页面切换都会通知小程序
  // useEffect(() => {
  //   if (global?.isInMiniprogram) {
  //     const win: any = window;
  //     alert(typeof win.wx.miniProgram.postMessage);
  //     win.wx.miniProgram.postMessage({ data: { event: 'urlChange', ... location } });
  //   }
  // }, [location.pathname, global?.isInMiniprogram]);

  if (global) {
    return <globalContext.Provider value={global}>
      <div className="mooncake">
        <Route path={match.path} exact component={IntroPage}></Route>
        <Route path={match.path + "/buy"} component={BuyPage}></Route>
        <Route path={match.path + "/form"} component={FormPage}></Route>
        <Route path={match.path + '/orders'} component={OrdersPage}></Route>
        <Route path={match.path + '/search-form'} component={SearchForm}></Route>
        <Route path={match.path + '/success-page'} component={SuccessPage}></Route>
      </div>
    </globalContext.Provider>;
  } else {
    return <div className="mooncake">
      加载中....
    </div>;
  }
}

export default (props: RouteComponentProps) => {
  const { location } = props;
  if (location.pathname.indexOf('/link-create') > -1) {
    return <div className="mooncake"><LinkCreator {... props} /></div>;
  } else {
    return <InnerRoute {... props} />;
  }
}
