import { createElement as _C, useState, useEffect } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { alert, defineRequest } from '../common';
import { PhoneField, checkPhoneFormat } from '../form';
import { FormInput } from './common';


const useRequestUserInfo = defineRequest<{ phone: string }, { result: null | { username: string, phonenumber: string }}>('/mooncake/getUserByPhoneNumber');

/**
 *  内部会员的链接生成页面
 */
export const LinkCreator = ({ history }: RouteComponentProps) => {
  const [state, request] = useRequestUserInfo();
  const phone = useState('');
  const user = state.data && state.data.result ? state.data.result : null;
  const [isInit, setInit] = useState(false);
  console.log(state);
  return <div className="search-form link-creator">
    <div className="bg">
       <img src={require('../../resources/img/bg.svg')} />
    </div>
    <div className="form-area">
      <img className="decorate-person" src={require('../../resources/img/search-form-deco.png')} />
      <div className="form-item">
        <label>手机号码</label>
        <FormInput model={phone} placeholder="请输入你的手机号码" />
        <a href="javascript:;" className="btn" onClick={() => {
          try {
            checkPhoneFormat(phone[0])
            if (!state.isLoading) {
              setInit(true);
              request({ phone: phone[0] });
            }
          } catch(err) {
            alert(err.message);
          }
        }}>
          {state.isLoading ? '验证中..' : 'Beers验证'}
        </a>
      </div>
    </div>

    {!isInit && <div className="form-area result-area">
        <div style={{ textAlign: 'center' }}>Dear Beers，为了方便统计您推荐好友购买Bee+ <br />
中秋月饼的信息，请填写以上信息生成购买链接 <br />
预祝伙伴们月饼大卖！</div>
    </div>}

    {user != null && <div className="form-area result-area">
      <div className="successs-info">Dear <span className="name">{user.username}</span>，恭喜您验证成功，请点击以下按钮 <br /> 生成推荐购买链接转发给好友</div>
    </div>}

    {user != null && <a href="javascript:;" className="submit-btn" onClick={() => history.push('/mooncake/buy?employee=' + user.phonenumber + '&employeeName=' + encodeURIComponent(user.username))}>
      生成推荐购买链接
    </a>}

    {user == null && isInit && <div className="form-area  result-area">
      <div className="info">非常抱歉，我们没有找到这个手机号码 <br /> 请联系商业技术发展中心小伙伴进行处理</div>
      <h1>联系方式</h1>
      <div className="form-item">
        <label>姓名</label>
        <span>王玉荣</span>
      </div>
      <div className="form-item">
        <label>手机号码</label>
        <span>15899773082</span>
      </div>
      <div className="form-item">
        <label>邮箱</label>
        <span>wangyurong@beeplus.cc</span>
      </div>
    </div>}
  </div>
}
